import React, { useEffect, useState } from "react";
import Signinimg from "../images/bookssign.png";
import GoogleButton from "react-google-button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  signInUser,
  clearState,
  signinWithGoogle,
} from "../redux/action/action";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getAuth,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

// Custom styles for TextField
const CssTextField = styled(TextField)({
  "& label": {
    color: "#FFFFFF", // White focus color for inputs
  },
  "& label.Mui-focused": {
    color: "#FFFFFF",
    backgroundColor: "#404040", // White focus color for inputs
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#FFFFFF",
    color: "#FFFFFF",
    // backgroundColor: "#404040",s
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#FFFFFF",
      borderColor: "#FFFFFF", // White borders
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF",
      color: "#FFFFFF",
      // White on hover
    },
    "&.Mui-focused fieldset": {
      color: "#FFFFFF", // White
      borderColor: "#FFFFFF",
      // backgroundColor: "#404040", // White when focused
    },
    "& input": {
      height: "0.8rem",
      fontFamily: "Poppins",
      fontSize: "14px",
      color: "#FFFFFF",
      // background: "#1f1f1f",
      // backgroundColor: "#404040",
      // backgroundColor: "#737888",
      // White text input
    },
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.75rem",
    color: "#FFFFFF", // White helper text
    backgroundColor: "#404040",
  },
});

// Custom styles for the button
const ColorButton = styled(Button)(() => ({
  width: "75%",
  padding: "10px 20px",
  fontSize: "1rem",
  fontFamily: "Poppins",
  backgroundColor: "#1f1f1f", // Black button background
  color: "white",
  "&:hover": {
    backgroundColor: "#1f1f1f",
    boxShadow: "0px 4px 12px rgba(142, 45, 226, 0.1)",
  },
  transition: "all 0.3s ease-in-out",
}));

const customStyles = {
  row: {
    // height: "100vh",
    "--bs-gutter-x": "0",
    // backgroundImage: "linear-gradient(to right, #8e2de2, #4a00e0)",
    // backgroundImage: "linear-gradient(to bottom, #000000, #404040, #ffffff)",
    backgroundColor: "#404040",
  },
  col: {
    height: "100%",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "2rem", // New gradient background
    color: "white", // White text color for readability
  },
};

const auth = getAuth();

export default function Signin() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false);
  const [users, setUsers] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signinreducer.user);
  const error = useSelector((state) => state.signinreducer.error);
  const loading = useSelector((state) => state.signinreducer.loading);
  const user1 = useSelector((state) => state.Google.user);
  const error2 = useSelector((state) => state.Google.error);

  useEffect(() => {
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, async (userAuth) => {
          if (userAuth) {
            setUsers(userAuth);
          } else {
            setUsers(null);
            navigate("/");
          }
        });
        return () => unsubscribe();
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
      });
  }, []);

  const authToken = users?.accessToken;
  useEffect(() => {
    if (authToken !== null && authToken !== undefined) {
      toast.success("Login successful");
      axios({
        method: "get",
        url: "https://app.simplymemoirs.com/api/singleuser",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then((response) => {
          if (!response.data) {
            navigate("/signin");
          } else {
            navigate("/books");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      dispatch(clearState());
    }
  }, [user1, user, authToken]);

  function signInwithGoogle() {
    dispatch(signinWithGoogle());
    if (authToken) {
      toast.success("Login successful");
      navigate("/books");
    } else {
      navigate("/signin");
    }
  }

  const isEmailValid = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newEmail = formData.email;
    if (!isEmailValid(newEmail)) {
      toast.error("Invalid Email");
      return;
    }
    if (formData.password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }
    dispatch(signInUser(formData));
  };

  return (
    <form className="col-12" onSubmit={handleSubmit}>
      <div className="row" style={customStyles.row}>
        <div className="col-6" style={customStyles.col}>
          <img src={Signinimg} width="100%" height="100%" alt="Loading" />
        </div>
        <div className="col-6" style={customStyles.col}>
          <div style={customStyles.contentWrapper}>
            <Typography
              variant="h3"
              sx={{
                color: "white", // White for main title
                fontWeight: "600",
                fontFamily: "Poppins",
                fontSize: "3vw",
                textAlign: "center",
                lineHeight: "1.2",
              }}
            >
              Welcome
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "white", // White for subtext
                fontWeight: "400",
                fontFamily: "Poppins",
                marginBottom: "2rem",
                fontSize: "1.1vw",
                textAlign: "center",
              }}
            >
              Login to your account
            </Typography>
            <CssTextField
              label="Email"
              name="email"
              value={formData.email}
              variant="outlined"
              sx={{ marginBottom: "1.5rem", width: "75%" }}
              placeholder="Email"
              InputProps={{
                startAdornment: (
                  <MailOutlineIcon sx={{ marginRight: 1, color: "#FFFFFF" }} />
                ),
              }}
              onChange={handleInputChange}
            />
            <CssTextField
              label="Password"
              name="password"
              variant="outlined"
              sx={{ marginBottom: "1.5rem", width: "75%", color: "#FFFFFF" }}
              InputProps={{
                startAdornment: (
                  <LockIcon sx={{ marginRight: 1, color: "#FFFFFF" }} />
                ),
                endAdornment: showPass ? (
                  <VisibilityIcon
                    onClick={() => setShowPass(!showPass)}
                    sx={{ cursor: "pointer", color: "#FFFFFF" }}
                  />
                ) : (
                  <VisibilityOffIcon
                    onClick={() => setShowPass(!showPass)}
                    sx={{ cursor: "pointer", color: "#FFFFFF" }}
                  />
                ),
              }}
              placeholder="Password"
              type={showPass ? "text" : "password"}
              value={formData.password}
              onChange={handleInputChange}
              helperText={
                "Password must be more than 6 characters with uppercase, lowercase and contain special characters."
              }
            />

            <div
              className="mt-2 d-flex justify-content-between"
              style={{ width: "75%" }}
            >
              <Link style={{ textDecoration: "none" }} to={"/signup"}>
                <Typography
                  variant="body2"
                  sx={{
                    color: "white", // White links
                    fontSize: "1vw",
                    fontFamily: "Poppins",
                  }}
                >
                  Register your account
                </Typography>
              </Link>
              {/* <Link style={{ textDecoration: "none" }} to={"/forgot"}> */}
              <Typography
                variant="body2"
                sx={{
                  color: "white", // White links
                  fontSize: "1vw",
                  fontFamily: "Poppins",
                }}
              >
                Forgot Password?
              </Typography>
              {/* </Link> */}
            </div>

            <ColorButton
              variant="contained"
              type="submit"
              sx={{ marginTop: 3 }}
              disabled={loading}
              onClick={handleSubmit}
            >
              Log in
            </ColorButton>

            <GoogleButton
              style={{
                marginTop: 20,
                width: "75%",
                height: "50px",
                fontSize: "1rem",
              }}
              onClick={signInwithGoogle}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </form>
  );
}
