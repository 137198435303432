// import React, { useState, useEffect } from "react";
// import ReactQuill from "react-quill";
// import axios from "axios";
// import { modules } from "../utils/helper";

// export default function Copyright() {
//   const [text, setText] = useState(`
//       <p
//         className='col-11'
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           backgroundColor: "#1E1E1E",
//           margin: "0 auto",
//           padding: "8px",
//           paddingLeft: "12px",
//           borderRadius: "5px",
//         }}
//       >
//         <h3>Copyright</h3>
//         <h1>Copyright</h1>
//       </p>
//       <p className='col-10' style={{ padding: "20px", margin: "20px auto" }}>
//         <h3>Copyright © 2024 by Simply Memoirs.</h3>
//         <br />
//         <p>All rights reserved. No part of this book may be reproduced or transmitted
//         in any form or by any means without written permission from the author. </p>
//         <br />
//         <p>
//           Printed and bound in the USA by Family Heritage Publishers
//         </p>
//       </p>
//     `);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const token = localStorage.getItem("token");
//         const response = await axios.get(
//           "http://localhost:3001/api/copyright",
//           {
//             headers: { Authorization: `Bearer ${token}` },
//           }
//         );

//         if (
//           response.data.status === "true" &&
//           response.data.copyright.length > 0
//         ) {
//           // Set existing content if available
//           setText(response.data.copyright[0]?.content || "");
//         } else {
//           // Set default text if no content is found
//           setText(`
//               <p className='col-11' style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 backgroundColor: "#1E1E1E",
//                 margin: "0 auto",
//                 padding: "8px",
//                 paddingLeft: "12px",
//                 borderRadius: "5px",
//               }}>
//                 <h3>Copyright</h3>
//                 <h1>Copyright</h1>
//               </p>
//               <p className='col-10' style={{ padding: "20px", margin: "20px auto" }}>
//                 <h3>Copyright © 2024 by Simply Memoirs.</h3>
//                 <br />
//                 <p>All rights reserved. No part of this book may be reproduced or transmitted
//                 in any form or by any means without written permission from the author.</p>
//                 <br />
//                 <p>Printed and bound in the USA by Family Heritage Publishers</p>
//               </p>
//             `);
//         }
//       } catch (error) {
//         console.error("Error fetching content:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleTextChange = async (content) => {
//     setText(content);

//     try {
//       const token = localStorage.getItem("token");
//       // Define your API payload with the actual content
//       const payload = {
//         content, // Pass the edited content from the editor
//         year: new Date().getFullYear(),
//         penName: "Author's Pen Name", // Replace with dynamic data if needed
//       };

//       // Make the POST request
//       const response = await axios.post(
//         "http://localhost:3001/api/copyright",
//         payload,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`, // Replace with actual token
//           },
//         }
//       );

//       if (response.data.status === "true") {
//         console.log("Successfully updated copyright:", response.data.book);
//       } else {
//         console.error("Error updating copyright:", response.data.error);
//       }
//     } catch (error) {
//       console.error("API error:", error);
//     }
//   };

//   return (
//     <div>
//       <div className="row">
//         <div className={"col-12"} style={{ position: "relative" }}>
//           <div
//             className="editor-container col-12"
//             style={{
//               borderRadius: "20px 20px 0px 0px",
//               borderLeft: "2px solid #503981",
//               borderRight: "2px solid #503981",
//               borderTop: "2px solid #503981",
//             }}
//           >
//             <div className={"col-10"}>
//               <h3 style={{ marginTop: "10px", display: "flex" }}>
//                 <input type="text" value="Copyright" disabled={true} />
//               </h3>
//             </div>
//           </div>
//           <ReactQuill
//             theme="snow"
//             modules={modules}
//             value={text}
//             onChange={handleTextChange} // Trigger API call on every update
//           />
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect, useCallback } from "react";
import ReactQuill from "react-quill";
import axios from "axios";
import { modules } from "../utils/helper";

export default function Copyright() {
  const [text, setText] = useState("");
  const [debouncedText, setDebouncedText] = useState(text);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          "https://app.simplymemoirs.com/api/copyright",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        if (
          response.data.status === "true" &&
          response.data.copyright.length > 0
        ) {
          setText(response.data.copyright[0]?.content || "");
        } else {
          setText(`
            <p className='col-11' style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#1E1E1E",
              margin: "0 auto",
              padding: "8px",
              paddingLeft: "12px",
              borderRadius: "5px",
            }}>
              <h3>Copyright</h3>
              <h1>Copyright</h1>
            </p>
            <p className='col-10' style={{ padding: "20px", margin: "20px auto" }}>
              <h3>Copyright © 2024 by Simply Memoirs.</h3>
              <br />
              <p>All rights reserved. No part of this book may be reproduced or transmitted
              in any form or by any means without written permission from the author.</p>
              <br />
              <p>Printed and bound in the USA by Family Heritage Publishers</p>
            </p>
          `);
        }
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchData();
  }, []);

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const handleDebouncedChange = useCallback(
    debounce(async (content) => {
      const token = localStorage.getItem("token");
      try {
        const payload = {
          content,
          year: new Date().getFullYear(),
          penName: "Author's Pen Name",
        };

        const response = await axios.post(
          "https://app.simplymemoirs.com/api/copyright",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.status === "true") {
          console.log("Successfully updated copyright:", response.data.book);
        } else {
          console.error("Error updating copyright:", response.data.error);
        }
      } catch (error) {
        console.error("API error:", error);
      }
    }, 1000), // 1 second delay
    []
  );

  const handleTextChange = (content) => {
    setText(content);
    handleDebouncedChange(content);
  };

  return (
    <div>
      <div className="row">
        <div className={"col-12"} style={{ position: "relative" }}>
          <div
            className="editor-container col-12"
            style={{
              borderRadius: "20px 20px 0px 0px",
              borderLeft: "2px solid #503981",
              borderRight: "2px solid #503981",
              borderTop: "2px solid #503981",
            }}
          >
            <div className={"col-10"}>
              <h3 style={{ marginTop: "10px", display: "flex" }}>
                <input type="text" value="Copyright" disabled={true} />
              </h3>
            </div>
          </div>
          <ReactQuill
            theme="snow"
            modules={modules}
            value={text}
            onChange={handleTextChange}
          />
        </div>
      </div>
    </div>
  );
}
