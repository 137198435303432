import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa";

const Dictaphone = ({ setValue }) => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    if (transcript) {
      const formattedTranscript = transcript.replace(/\n/g, " ").trim();

      setValue((prevValue) => {
        const isLastParagraphOpen = prevValue.endsWith("</p>");

        if (isLastParagraphOpen) {
          return prevValue.replace(/<\/p>$/, ` ${formattedTranscript}</p>`);
        } else {
          return `${prevValue}<p>${formattedTranscript}</p>`;
        }
      });

      resetTranscript(); // Optionally reset the transcript after updating the value
    }
  }, [transcript, setValue, resetTranscript]);

  const handleToggleListening = () => {
    if (isListening) {
      setTimeout(() => {
        SpeechRecognition.stopListening();
      }, 1000); // Adjust timeout as needed
    } else {
      SpeechRecognition.startListening({ continuous: true });
    }
    setIsListening(!isListening);
  };

  // Check for browser support after all hooks
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div>
      <button style={{ background: "white" }} onClick={handleToggleListening}>
        {isListening ? (
          <FaMicrophoneSlash style={{ fontSize: "32px", color: "#503981" }} />
        ) : (
          <FaMicrophone style={{ fontSize: "32px", color: "#503981" }} />
        )}
      </button>
    </div>
  );
};

export default Dictaphone;
