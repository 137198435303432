import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FeedIcon from "@mui/icons-material/Feed";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PrintIcon from "@mui/icons-material/Print";
import ScreenRotationOutlinedIcon from "@mui/icons-material/ScreenRotationOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { CiDeliveryTruck } from "react-icons/ci";
import { MdOutlineMenuBook } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { fetchPdf } from "../../redux/action/action";
import {
  BookSizeDetail,
  BookTypeDetail,
  BookWeightDetail,
  CopiesDetails,
  CoverFinishDetail,
  DeliveryMethodDetail,
  OrientationDetail,
  PagesColorDetail,
  PaperWeightDetail,
  PrintQualityDetail,
} from "./Detail";

const steps = [
  {
    label: "Step 1",
    detailComponent: <BookTypeDetail />,
    completed: false,
  },
  {
    label: "Step 2",
    detailComponent: <PrintQualityDetail />,
    completed: false,
  },
  {
    label: "Step 3",
    detailComponent: <PaperWeightDetail />,
    completed: false,
  },
  {
    label: "Step 4",
    detailComponent: <BookSizeDetail />,
    completed: false,
  },
  {
    label: "Step 5",
    detailComponent: <OrientationDetail />,
    completed: false,
  },
  {
    label: "Step 6",
    detailComponent: <BookWeightDetail />,
    completed: false,
  },
  {
    label: "Step 7",
    detailComponent: <DeliveryMethodDetail />,
    completed: false,
  },
  {
    label: "Step 8",
    detailComponent: <CoverFinishDetail />,
    completed: false,
  },
  {
    label: "Step 9",
    detailComponent: <PagesColorDetail />,
    completed: false,
  },
  {
    label: "Step 10",
    detailComponent: <CopiesDetails />,
    completed: false,
  },
];

export default function Orders() {
  const [activeStep, setActiveStep] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  // const [bookDetails, setBookDetails] = useState({
  //   bookType: { type: null, price: 0 },
  //   printQuality: { type: null, price: 0 },
  //   paperWeight: { type: null, price: 0 },
  //   bookSize: { type: null, price: 0 },
  //   orientation: { type: null, price: 0 },
  //   bookWeight: { type: null, price: 0 },
  //   deliveryMethod: { type: null, price: 0 },
  //   coverFinish: { type: null, price: 0 },
  // });

  const [bookDetails, setBookDetails] = useState({
    bookType: { type: "Hard Cover", price: 25 }, // Default type and price for demonstration
    printQuality: { type: "Economy", price: 5 },
    paperWeight: { type: "100lb", price: 5 },
    bookSize: { type: "6x9", price: 20 },
    orientation: { type: "Landscape", price: 5 },
    bookWeight: { type: "Small", price: 0.7452 },
    deliveryMethod: { type: "Express Delivery", price: 15 },
    coverFinish: { type: "Matte Finish", price: 5 },
    noofcopies: { type: 1 }, // Added initial value for number of copies
  });

  const bookId = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedPageSize = useSelector(
    (state) => state.pageSize.selectedPageSize
  );
  useEffect(() => {
    dispatch(fetchPdf(bookId.id, selectedPageSize));
  }, [dispatch, bookId.id, selectedPageSize]);

  const [priceBreakdown, setPriceBreakdown] = useState(null);

  const calculatePrice = (bookDetails) => {
    // Constants for cost per page and base cost
    const fixedCost = 24.5;
    const bwPageCost =
      bookDetails.printQuality.type === "Economy" ? 0.02 : 0.03;
    const colorPageCost =
      bookDetails.printQuality.type === "Economy" ? 0.1 : 0.12;
    const paperWeightCost = bookDetails.paperWeight.type === "100lb" ? 0.02 : 0;

    // Sum up the base prices from bookDetails
    let totalPrice = 0;
    totalPrice += bookDetails.bookType.price || 0;
    totalPrice += bookDetails.printQuality.price || 0;
    totalPrice += bookDetails.bookSize.price || 0;
    totalPrice += bookDetails.orientation.price || 0;
    totalPrice += bookDetails.coverFinish.price || 0;

    // Calculate the number of black and white and color pages
    const pages = bookDetails.printModes?.type;
    const { blackAndWhite, color } = separatePages(pages);

    // Calculate the cost of pages
    const pageCost =
      blackAndWhite * (bwPageCost + paperWeightCost) +
      color * (colorPageCost + paperWeightCost);

    // Parse number of copies
    const numCopies = parseInt(bookDetails.noofcopies?.type);
    if (isNaN(numCopies) || numCopies <= 0) {
      console.error(
        "Invalid number of copies: Please enter a valid positive number."
      );
      return { error: "Invalid number of copies." };
    }

    // Calculate the margin factor based on the number of copies
    let marginFactor = 0;
    let smallOrderFee = 0;

    if (numCopies < 8) {
      console.error("Minimum order is 8 books.");
      toast.error("Minimum order is 8 books.");
      return { error: "Minimum order is 8 books." };
    } else if (numCopies < 24) {
      marginFactor = 0.4;
      smallOrderFee = 150; // Small order fee for fewer than 24 copies
    } else if (numCopies >= 24 && numCopies <= 49) {
      marginFactor = 0.425;
    } else if (numCopies >= 50 && numCopies <= 99) {
      marginFactor = 0.45;
    } else if (numCopies >= 100 && numCopies <= 199) {
      marginFactor = 0.475;
    } else if (numCopies >= 200 && numCopies <= 299) {
      marginFactor = 0.5;
    } else if (numCopies >= 300 && numCopies <= 499) {
      marginFactor = 0.525;
    } else if (numCopies >= 500) {
      marginFactor = 0.55;
    }

    // Calculate the total book cost
    const bookCost = fixedCost + pageCost + totalPrice;

    // Calculate the final price per book using the margin factor
    const finalPricePerBook = bookCost / marginFactor;

    // Calculate the grand total including small order fee if applicable
    const grandTotal = finalPricePerBook * numCopies + smallOrderFee;

    // Return the breakdown of all calculations
    return {
      fixedCost,
      bwPageCost,
      colorPageCost,
      paperWeightCost,
      totalPrice,
      pageCost,
      numCopies,
      marginFactor,
      smallOrderFee,
      bookCost,
      finalPricePerBook,
      grandTotal: grandTotal.toFixed(2),
    };
  };

  // Helper function to separate pages into color and black and white
  const separatePages = (pages) => {
    let blackAndWhite = 0;
    let color = 0;

    for (const page in pages) {
      if (pages[page] === "bw") {
        blackAndWhite++;
      } else if (pages[page] === "color") {
        color++;
      }
    }

    return { blackAndWhite, color };
  };

  const handleCheckout = () => {
    const selectedValues = {
      bookType: bookDetails.bookType,
      printQuality: bookDetails.printQuality,
      paperWeight: bookDetails.paperWeight,
      bookSize: bookDetails.bookSize,
      orientation: bookDetails.orientation,
      bookWeight: bookDetails.bookWeight,
      deliveryMethod: bookDetails.deliveryMethod,
      coverFinish: bookDetails.coverFinish,
      noofcopies: bookDetails.noofcopies,
      printModes: bookDetails.printModes,
      price: totalPrice,
    };
    navigate(`/checkout/${bookId.id}`, { state: { selectedValues } }); // Navigate to Checkout with selected values
  };

  const handleNext = () => {
    if (!steps[activeStep].completed) {
      toast.error("Please select the option before moving to next step");
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleCalculatePrice = () => {
  //   const price = calculatePrice(bookDetails);
  //   console.log(price);
  //   setTotalPrice(price);
  // };

  const handleCalculatePrice = () => {
    const breakdown = calculatePrice(bookDetails);
    if (breakdown.error) {
      toast.error(breakdown.error);
    } else {
      setPriceBreakdown(breakdown);
      setTotalPrice(breakdown.grandTotal);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleDetailChange = (detailName, value, price) => {
    setBookDetails((prevDetails) => ({
      ...prevDetails,
      [detailName]: { type: value, price: price },
    }));
    steps[activeStep].completed = true;
  };

  const theme = createTheme({
    palette: {
      mode: "light",
      background: {
        default: "#ffffff", // Set the background color for dark mode
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
            marginTop: "50px",
          }}
        >
          <div
            style={{ width: "60%", display: "flex", flexDirection: "column" }}
          >
            <div style={{ display: "flex" }}>
              <Box
                sx={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  // marginTop:"30px"
                }}
              >
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "60%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Book Type</p>
                      <MenuBookIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Print Quality</p>
                      <PrintIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Paper Weight</p>
                      <FeedIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Book Size</p>
                      <MenuBookIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Orientation</p>
                      <ScreenRotationOutlinedIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Book Weight</p>
                      <ScreenRotationOutlinedIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Delivery Method</p>
                      <CiDeliveryTruck
                        style={{ fontWeight: "700", fontSize: "30px" }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Cover Finish </p>
                      <MdOutlineMenuBook />
                    </div>
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Num of Pages </p>
                      <MdOutlineMenuBook />
                    </div> */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Page Color </p>
                      <MdOutlineMenuBook />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                        width: "100%",
                        border: "0.5px solid #EAEAEA",
                        padding: "8px",
                        borderRadius: "5px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <p style={{ marginBottom: "0px" }}>Copies</p>
                      <MdOutlineMenuBook />
                    </div>
                  </div>
                  <div style={{ width: "40%", paddingLeft: "10px" }}>
                    <Stepper
                      activeStep={activeStep}
                      orientation="vertical"
                      sx={{ height: "100%" }}
                    >
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel>{step.label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
              </Box>
              <DialogContent
                sx={{
                  width: "60%",
                  padding: "45px 45px 85px 45px",
                  border: "0.5px solid #EAEAEA",
                  borderRadius: "5px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  height: "555px",
                }}
              >
                {React.cloneElement(steps[activeStep].detailComponent, {
                  onChange: handleDetailChange,
                  selectedPageSize,
                })}
                {activeStep === steps.length - 1 && (
                  <>
                    <Button
                      style={{ width: "100%", margin: "auto", height: "40px" }}
                      onClick={handleCalculatePrice}
                    >
                      Calculate Total Price
                    </Button>
                    <p
                      style={{
                        width: "100%",
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      Total Price: $
                      <span style={{ fontWeight: "bold" }}>
                        {totalPrice ? totalPrice : 0}
                      </span>
                    </p>
                  </>
                )}
              </DialogContent>
            </div>
            <div
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "space-evenly",
                alignSelf: "flex-end",
                // marginTop: "10px",
              }}
            >
              {activeStep !== 0 && (
                <Button onClick={handleBack} style={{ marginRight: "10px" }}>
                  <ChevronLeftIcon />
                  Back
                </Button>
              )}
              {activeStep !== steps.length - 1 && (
                <Button
                  style={{ width: "100%", margin: "auto" }}
                  onClick={() => {
                    handleNext();
                    // handleCalculatePrice();
                  }}
                >
                  Next
                  <ChevronRightIcon />
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignSelf: "flex-end",
                  }}
                >
                  <Button onClick={handleCheckout}>Checkout</Button>
                </div>
              )}
            </div>
          </div>
          <ToastContainer />
          <div>
            {priceBreakdown && (
              <div
                style={{
                  marginTop: "10px",
                  padding: "8px",
                  border: "1px solid #EAEAEA",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  overflowY: "auto", // Allow vertical scrolling if necessary
                  fontSize: "14px",
                  marginLeft: "30px",
                  // Reduce font size to make content more compact
                }}
              >
                <h3 style={{ margin: "0 0 8px 0" }}>Calculation Breakdown:</h3>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "8px",
                  }}
                >
                  <p>Fixed Cost:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.fixedCost}
                  </p>
                  <p>Black & White Page Cost:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.bwPageCost}
                  </p>
                  <p>Color Page Cost:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.colorPageCost}
                  </p>
                  <p>Paper Weight Cost:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.paperWeightCost}
                  </p>
                  <p>Total Base Price:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.totalPrice}
                  </p>
                  <p>Page Cost:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.pageCost}
                  </p>
                  <p>Number of Copies:</p>
                  <p style={{ fontWeight: "bold" }}>
                    {priceBreakdown.numCopies}
                  </p>
                  <p>Margin Factor:</p>
                  <p style={{ fontWeight: "bold" }}>
                    {priceBreakdown.marginFactor}
                  </p>
                  <p>Small Order Fee:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.smallOrderFee}
                  </p>
                  <p>Total Book Cost:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.bookCost}
                  </p>
                  <p>Final Price per Book:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.finalPricePerBook.toFixed(2)}
                  </p>
                  <p>Grand Total:</p>
                  <p style={{ fontWeight: "bold" }}>
                    ${priceBreakdown.grandTotal}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}
