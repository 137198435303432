import "../components/custom-quill.css";
import AWS from "aws-sdk";
import { paraphrase } from "../api/api";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { preview } from "../redux/action/action";
// import CustomImageResize from "../components/CustomImageResize";
import ImageResize from "quill-image-resize-module-react";
import CustomImageDelete from "../components/CustomImageDelete";
// import PageBreakModule from "../components/PageBreak";
// import SceneBreakModule from "../components/SceneBreak";
// import FilterModule from "../components/Filter";
import {
  CardEditableModule,
  ImageBlot,
} from "../components/quill-figure-with-caption";
import ImageUploader from "quill-image-uploader";
Quill.register("modules/imageResize", ImageResize);

// import "quill-image-uploader/dist/quill.imageUploader.min.css";
// Quill.register("modules/pageBreak", PageBreakModule);
// Quill.register("modules/sceneBreak", SceneBreakModule);
Quill.register("modules/imageUploader", ImageUploader);
// Quill.register("modules/customImageResize", CustomImageResize);
Quill.register("modules/customImageDelete", CustomImageDelete);
// Quill.register("modules/filter", FilterModule);

var BaseImageFormat = Quill.import("formats/image");
const ImageFormatAttributesList = ["alt", "height", "width", "style"];
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    if (domNode.tagName.toLowerCase() === "img") {
      return {
        width: domNode.getAttribute("width"),
        style: domNode.getAttribute("style"),
      };
    }

    var imageNode = domNode.querySelector("img");
    let figcaption = domNode.querySelector("figcaption");
    const style = imageNode?.style;
    if (figcaption) {
      figcaption.style.display = style?.display;
      figcaption.style.margin = style?.margin;

      let widths = imageNode?.width;
      figcaption.style.width = "fit-content";
      figcaption.style.float = style?.float;
      if (style?.float === "left") {
        figcaption.style.textAlign = "left";
      } else if (style?.float === "right") {
        figcaption.style.textAlign = "right";
      } else {
        figcaption.style.textAlign = "center";
      }
    }
    if (imageNode) {
      return ImageFormatAttributesList.reduce(function (formats, attribute) {
        if (imageNode.hasAttribute(attribute)) {
          formats[attribute] = imageNode.getAttribute(attribute);
        }
        return formats;
      }, {});
    }

    return {};
  }

  format(name, value) {
    // Track added event listeners
    this.imageClickHandler = this.imageClickHandler.bind(this);
    this.documentClickHandler = this.documentClickHandler.bind(this);

    if (name === "width" || ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        if (this.domNode.tagName.toLowerCase() === "img") {
          this.domNode.setAttribute(name, value);
        } else {
          var imageNode = this.domNode.querySelector("img");
          imageNode?.addEventListener("click", this.imageClickHandler);
          if (imageNode) {
            imageNode.setAttribute(name, value);
          }
        }
      } else {
        if (this.domNode.tagName.toLowerCase() === "img") {
          this.domNode.removeAttribute(name);
        } else {
          var imageNode = this.domNode.querySelector("img");
          if (imageNode) {
            imageNode.removeAttribute(name);
          }
        }
      }
    } else {
      super.format(name, value);
    }
  }

  removeEventListeners() {
    var imageNode = this.domNode.querySelector("img");
    if (imageNode) {
      imageNode.removeEventListener("click", this.imageClickHandler);
    }
    document.removeEventListener("click", this.documentClickHandler);
  }

  imageClickHandler() {
    var qlFilterElement = document.querySelector(".ql-filter");
    var qlBorderElement = document.querySelector(".ql-borders");
    var qlBorderSizeElement = document.querySelector(".ql-border-size");
    var qlShadowElement = document.querySelector(".ql-shadow");

    if (
      qlFilterElement &&
      qlBorderElement &&
      qlBorderSizeElement &&
      qlShadowElement
    ) {
      qlFilterElement.classList.add("list");
      qlBorderElement.classList.add("list");
      qlBorderSizeElement.classList.add("list");
      qlShadowElement.classList.add("list");

      document.addEventListener("click", this.documentClickHandler);
    }
  }

  documentClickHandler(event) {
    var clickedElement = event.target;
    var qlFilterElement = document.querySelector(".ql-filter");
    var qlBorderElement = document.querySelector(".ql-borders");
    var qlBorderSizeElement = document.querySelector(".ql-border-size");
    var qlShadowElement = document.querySelector(".ql-shadow");

    if (
      !clickedElement.closest("img") &&
      !clickedElement.classList.contains("list")
    ) {
      qlFilterElement.classList.remove("list");
      qlBorderElement.classList.remove("list");
      qlBorderSizeElement.classList.remove("list");
      qlShadowElement.classList.remove("list");

      document.removeEventListener("click", this.documentClickHandler);
    }
  }
}

Quill.register(ImageFormat, true);

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: "us-west-1",
});

const s3 = new AWS.S3();

export let transferData;

export const extractWordsAfterSlash = (text) => {
  const regex = /\/([^<>\r\n]+)/;

  const match = text.match(regex);
  if (match && match[1]) {
    return match[1].trim();
  }
  return "";
};

export const stripHtmlTags = (html) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerHTML || "";
};

export const paraphrasing = async (usertext) => {
  const text = stripHtmlTags(usertext);
  if (text !== "") {
    const { data } = await paraphrase(text);
    return data[0].text;
  } else {
    alert("Please Enter some text");
    return "";
  }
};

export const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { color: [] },
      {
        background: [],
      },
    ],
    [{ size: ["normal", "small", "large", "huge"] }, "size"],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ font: [] }],
    [{ align: [] }],
    ["image"],
    // ["pageBreak"],
    ["sceneBreak"],
    ["separator"],
    ["filter"],
    ["borders"],
    ["border-size"],
    ["shadow"],
  ],

  // filter: true,
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const randomString = Math.floor(Math.random() * 101);
        const fileName = `${file.name}-${randomString}`;
        const params = {
          Bucket: "simplymemoirs",
          Key: `images/${fileName}`,
          Body: file,
          ACL: "public-read",
        };
        s3.upload(params, (err, data) => {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            console.log(`File uploaded successfully. URL: ${data.Location}`);
            resolve(data.Location);
          }
        });
      });
    },
  },
  clipboard: {
    matchVisual: false,
  },
  imageResize: {
    parchment: Quill.import("parchment"),
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },
  cardEditable: true,
};

export const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "image",
  "font",
  "color",
  "background",
  "script",
  "indent",
  "align",
  "paraphrasebtn",
  "save",
  "preview",
  // "pageBreak",
  "sceneBreak",
  "size", // Add font size format
  "caption",
  "width",
  "style",
  "margin",
  "float",
  "display",
  "imageBlot",
  "filter",
  "shadow",
  "borders",
  "border-size",
];
